import React from 'react';
import Link from 'next/link';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import useScrollToTop from 'hooks/useScrollToTop';
import VideoIcon from 'public/icons/video-player-thin.svg';
import SoundIcon from 'public/icons/sound-thin.svg';
import HeartIcon from 'public/icons/heart-thin.svg';
import CameraIcon from 'public/icons/camera-thin.svg';
import DashedArrowIcon from 'public/icons/dashed-arrow.svg';
import DocumentHead from 'components/DocumentHead';
import AnimateListItem from 'components/animations/AnimateListItem';
import AnimateList from 'components/animations/AnimateList';
import styles from 'styles/components/home/Links.module.scss';

const iconStyle = { width: '100%', textAlign: 'center', marginBottom: 50 };

const sectionList = [
  {
    id: 1,
    title: 'Wishlist',
    description: 'Set up your wishlist and let your fans support you.',
    icon: <HeartIcon />,
  },
  {
    id: 2,
    title: 'Custom Videos',
    description: 'Fans now have a place to order a custom video from you based on your set rates.',
    icon: <VideoIcon />,
  },
  {
    id: 3,
    title: 'Social Media Promo',
    description: 'Set your pricing for promo on all the major social networks.',
    icon: <SoundIcon />,
  },
  {
    id: 4,
    title: '1 on 1 video chat',
    description: 'Let your fans book you for a video chat.',
    icon: <CameraIcon />,
  },
];

const LinkPage = () => {
  useScrollToTop();

  return (
    <div className={styles.homeContainer}>
      <DocumentHead>
        <title>Buy & Sell Shoutouts | GigSocial</title>

        <meta
          property="og:title"
          content="The easiest way to buy and sell shoutouts"
          key="og:title"
        />
      </DocumentHead>

      <div className={styles.mask}>
        <div className={styles.background}>
          <div className={styles.rightSection}>
            <div className={styles.angle} />

            <Hidden smUp>
              <>
                <h2>Custom. Clean. Linkpages for Content Creators</h2>

                <div>
                  <h3 style={{ margin: '30px auto 0', maxWidth: 370 }}>
                    Launch a link page with GigSocial to start monetizing your brand to its full
                    potential today.
                  </h3>
                </div>
              </>
            </Hidden>
          </div>
        </div>
      </div>

      <Grid container className={clsx(styles.coverImage, styles.landingCover)}>
        <Grid item xs={12} sm={5} lg={4} className={styles.imgContainer}>
          <img
            src="/images/home/smaller-links.png"
            srcSet="images/home/smaller-links@2x.png"
            alt="Link Page"
            width="100%"
          />

          <img
            src="/images/home/larger-links.png"
            srcSet="images/home/larger-links@2x.png"
            alt="Link Page"
            width="100%"
          />
        </Grid>

        <Grid item xs={12} sm={7} lg={8} className={styles.rightSectionContainer}>
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <Hidden xsDown>
              <div className={styles.rightSection}>
                <h2>Custom. Clean. Linkpages for Content Creators</h2>

                <div>
                  <h3 style={{ margin: '30px auto 0 auto', maxWidth: 583 }}>
                    Launch a link page with GigSocial to start monetizing your brand to its full
                    potential today.
                  </h3>
                </div>
              </div>
            </Hidden>

            <div className={styles.topButtons}>
              <Link href={'/sign-up/influencer'} passHref>
                <Button variant="contained" color="primary">
                  Get Started
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className={styles.content} style={{ backgroundImage: 'none' }}>
        <Hidden mdUp>
          <div className={styles.whiteBackground} />
          <div className={styles.shadow} />
        </Hidden>

        <AnimateList key={sectionList[0].id} className={styles.homeSection}>
          <h4>ONE APP TO SELL IT ALL!</h4>

          <AnimateListItem>
            <div className={styles.section}>
              <ul>
                {sectionList.map(section => (
                  <li key={section.id} y>
                    {section.icon}
                    <h3>{section.title}</h3>
                    <p>{section.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </AnimateListItem>
        </AnimateList>

        <h2 className={styles.text}>
          All for only an industry leading 5% fee on orders through your link page.
        </h2>

        <div className={styles.bottomSection}>
          <h3>Join over 4,000 creators currently selling on GigSocial</h3>

          <DashedArrowIcon style={iconStyle} />

          <div>
            <Link href="/sign-up/influencer" passHref>
              <Button variant="contained" color="primary">
                Get Started
              </Button>
            </Link>
          </div>

          <div style={{ paddingTop: 80 }}>
            <span style={{ fontFamily: 'Avenir-Book', fontSize: 18 }}>
              Still got questions? Check out our{' '}
              <strong>
                <a
                  href="https://gigsocial.crisp.help/en-us/category/influencers-14y6c7a/"
                  target="blank"
                  rel="noreferrer noopener"
                >
                  FAQ
                </a>
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkPage;
